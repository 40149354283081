import React, { Component } from "react";
import { Scrollbar } from "react-scrollbars-custom";
import Routes from "../routes";
import { NavLink } from "react-router-dom";
import { NavbarMenu } from "../data/navbar";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import WelcomeMsg from "../components/WelcomeScreen";
import Utility from "../shared/utility";
export default class App extends Component {
  constructor() {
    super();
    this.utility = new Utility();
    this.loadingTimer = 2000;
    this.state = {
      appLoaded: false,
      showPortfolio: false,
      hideCustomMousePointer: this.utility.isMobileDevice(),
      welcomeScreenProps: {
        msg: "Hey There..",
        textClass: "fadeOutUp",
        textDisplay: "none",
        btnDisplay: "hidden",
        btnClass: "fadeOutUp",
      },
      toggleHamburgerVisibility: false,
    };
  }
  async componentDidMount() {
    await this.utility.delayOperation(this.loadingTimer);
    this.setState({
      appLoaded: true,
      welcomeScreenProps: {
        textDisplay: true,
        textClass: "fadeInDown",
      },
    });
    document.querySelector('.loadingContent').classList.add('fadeOut');
    await this.utility.delayOperation(this.loadingTimer);
    this.setState({
      welcomeScreenProps: {
        textClass: "fadeOutUp",
      },
    });
    await this.utility.delayOperation(500);
    this.setState({
      welcomeScreenProps: {
        msg: "Welcome To My Portfolio!",
        textClass: "fadeInDown",
        btnDisplay: "visible",
      },
    });
    await this.utility.delayOperation(1000);
    this.setState({
      welcomeScreenProps: {
        btnClass: "opacity1"
      },
    });
    // if (!this.hideCustomMousePointer) {
    //   this.utility.expandMousePointerOnLinkHover('.pointer');
    // }
  }

  enterBtnClick = () => {
    this.setState({
      showPortfolio: true,
      appLoaded: false,
    });
  }

  navBarClick = () => { }

  onHamburgerClick = (e) => {
    this.setState({
      toggleHamburgerVisibility: e,
    });
  }

  render() {
    const { appLoaded, showPortfolio, welcomeScreenProps, toggleHamburgerVisibility, hideCustomMousePointer } = this.state;
    const welcomeMsg = { ...welcomeScreenProps, visible: appLoaded };
    const headerProps = { visible: showPortfolio, toggleMenu: toggleHamburgerVisibility };
    const mobileMenu = NavbarMenu.map((item, index) => (
      <NavLink key={`nav-${index}`} to={item.link} onClick={() => this.onHamburgerClick(false)}>{item.label}</NavLink>
    ));
    return (
      <div>
        {/* {!hideCustomMousePointer && <MousePointer />} */}
        <div className={`container ${hideCustomMousePointer ? 'isMobile' : ''}`}>
          <div className={`mobileMenu ${toggleHamburgerVisibility ? 'fadeInUp z1' : 'fadeOutDown z-1'}`} style={{ visibility: showPortfolio ? "" : "hidden" }}>
            {mobileMenu}
          </div>
          <NavBar {...headerProps} onClick={this.navBarClick} onMobileMenuClick={this.onHamburgerClick} />
          <div className={`midContent ${toggleHamburgerVisibility ? "opacity0" : "opacity1"}`}>
            <WelcomeMsg {...welcomeMsg} onClick={this.enterBtnClick} />
            <Scrollbar>
              <Routes />
            </Scrollbar>
          </div>
          <Footer visible={showPortfolio} />
        </div>
      </div>
    );
  }
}