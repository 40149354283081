import React, {lazy, Suspense} from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import ErrorBoundary from '../components/ErrorBoundary';
const About = lazy(() => import("../components/About"));
const Skills = lazy(() => import("../components/Skills"));
const Resume = lazy(() => import("../components/Resume"));
const Work = lazy(() => import("../components/Work"));
const four04 = lazy(() => import("../components/four04"));
const fallBack = <div className="fallBackDiv"><span className="loader-icon"></span><h4>Loading...</h4></div>;
const Routes = () => (
  <ErrorBoundary>
    <Suspense fallback={fallBack}>
      <Switch>
        <Route exact path={["/","/about"]} component={About}/>
        <Route exact path="/skills" component={Skills} />
        <Route exact path="/work" component={Resume} />
        <Route exact path="/portfolio" component={Work} />
        <Route path="*" component={four04} />
      </Switch>
    </Suspense>
  </ErrorBoundary>
);
export default withRouter(Routes);