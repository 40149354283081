import React, {Component} from 'react';
export default class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menu: [
        // {name: "Facebook", link: "https://www.facebook.com/sharjeel.imtiaz69", class: "facebook-icon"},
        {name: "Github", link: "https://github.com/sharjeel619", class: "github-icon"},
        {name: "LinkedIn", link: "https://www.linkedin.com/in/sharjeelimtiaz", class: "linkedin-icon"},
        {name: "Medium" , link: "https://sharjeelimtiaz.medium.com", class: "medium-icon" },
        {name: "Email", link: "mailto:imtiazsharjeel@gmail.com", class: "email-icon"}
      ]
    };
  }

  static getDerivedStateFromProps(newProps) {
    return newProps;
  }

  render() {
    const footerItems = this.state.menu.map((item, index) => <li key={index}>
    {<a target="_blank" rel="noopener noreferrer" href={item.link}><i className={item.class}/></a>}</li>);
    const {visible} = this.state;
    return (
      <div className={`footer ${visible ? 'fadeIn' : ''}`}>
        <ul>
          {footerItems}
        </ul>
      </div>
    );
  }
}