import React, {Component} from "react";
export default class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="fallBackDiv">
          <h4>Uh Oh! Looks like something went wrong.</h4>
          <img src="https://media4.giphy.com/media/ZE5DmCqNMr3yDXq1Zu/giphy.gif" alt="You got rick rolled son" />
        </div>
      );
    }
    return this.props.children;
  }
}