export default class Utility {
  lineBreaker(str) {
    return str.split("/n");
  }
  objectKeyToString(val, char) {
    return val.replace("_", char);
  }
  delayOperation = (time) => {
    return new Promise(resolve => setTimeout(resolve, time));
  }
  expandMousePointerOnLinkHover = (elem) => {
    let chosenElem = elem || "a";
    let cursor = document.querySelector(".cursor");
    if (!cursor) return;
    const cb = () => {
      document.querySelectorAll(chosenElem).forEach(item => {
        item.addEventListener("mouseover", () => {
          cursor.classList.add("showArrow");
        });
        item.addEventListener("mouseout", () => {
          cursor.classList.remove("showArrow");
        });
      });
    }
    setTimeout(cb, 10)
  }
  isMobileDevice = () => {
    return (/Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(navigator.userAgent))
  }
  objectLength = (obj) => {
    return obj && Object.keys(obj).length;
  }
}