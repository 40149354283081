import React, { Component } from 'react';
import Utility from "../../shared/utility";
export default class WelcomeMessage extends Component {
  constructor(props) {
    super(props);
    this.delay = 400;
    this.utility = new Utility();
    this.state = { ...this.props, hideScreen: false, screenBlock: false };
  }

  static getDerivedStateFromProps(newProps, prevState) {
    return { ...prevState, ...newProps };
  }

  hideWelcomeScreen = (e) => {
    e.persist();
    this.props.onClick();
    this.setState({
      hideScreen: true,
    });
    this.utility.delayOperation(400).then(() => {
      this.setState({
        screenBlock: true,
      });
    });
  }

  render() {
    const { textClass, textDisplay, btnClass, btnDisplay, msg, hideScreen, screenBlock } = this.state;
    return (
      <div className={`welcomeMessage ${hideScreen ? "fadeOut" : ""} ${screenBlock ? "dNone" : ""}`}>
        <h1 className={`${textClass}`} style={{ display: textDisplay }}>{msg}</h1>
        <button onClick={(e) => this.hideWelcomeScreen(e)} className={`btnTransparent btnFillLeft skewedBtn pointer ${btnClass}`} style={{ visibility: btnDisplay }}>
          <div className="fill"></div>
          <span>Enter</span>
        </button>
      </div>
    )
  }
}